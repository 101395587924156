// app/javascript/controllers/secondary_tabs_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tab"];

  connect() {
    this.setActiveTab();
    this.setupClickListeners();
  }

  setActiveTab() {
    const activeScheduleId = document.querySelector("#activeMenuSchedule")
      ?.dataset.activeScheduleId;

    if (activeScheduleId) {
      this.tabTargets.forEach((tab) => {
        if (tab.dataset.scheduleId === activeScheduleId) {
          tab.classList.add("btn-light-red");
        } else {
          tab.classList.remove("btn-light-red");
        }
      });
    }
  }

  updateActiveTab(event) {
    this.tabTargets.forEach((t) => t.classList.remove("btn-light-red"));
    event.currentTarget.classList.add("btn-light-red");
  }

  setupClickListeners() {
    this.tabTargets.forEach((tab) => {
      tab.addEventListener("click", (event) => this.updateActiveTab(event));
    });
  }
}
