import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["icon", "checkmark"];

  copy(event) {
    event.preventDefault();

    const phoneNumber = this.iconTarget.getAttribute(
      "data-copy-to-clipboard-value"
    );

    navigator.clipboard.writeText(phoneNumber).then(() => {
      this.iconTarget.classList.add("d-none");
      this.checkmarkTarget.classList.remove("d-none");

      setTimeout(() => {
        this.iconTarget.classList.remove("d-none");
        this.checkmarkTarget.classList.add("d-none");
      }, 2000);
    });
  }
}
