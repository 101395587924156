export class SeatPositionCalculator {
  constructor(
    tableId,
    seatCount,
    shape,
    left,
    top,
    height,
    width,
    radius,
    scaleX,
    scaleY,
    angle,
    table
  ) {
    this.tableId = tableId;
    this.seatCount = seatCount;
    this.shape = shape;
    this.left = left;
    this.top = top;
    this.height = height;
    this.width = width;
    this.radius = radius;
    this.scaleX = scaleX;
    this.scaleY = scaleY;
    this.angle = angle;
    this.table = table;
  }

  addSeatsToCanvas() {
    let positions = this.#calculateSeatPositions();
    if (!positions) return;

    const canvas = document.getElementById("canvas").fabric;
    let tableId = this.tableId;
    let chairs = [];

    positions.forEach(function (pos) {
      var circle = new fabric.Circle({
        left: pos.left,
        top: pos.top,
        radius: 8,
        fill: "#6D6D6D",
        originX: "center",
        originY: "center",
        selectable: false,
      });

      chairs.push(circle);
    });

    if (this.table.shape == 10 || this.table.shape == 11) {
      chairs.push(this.#addInvisibleChairs());
    } else if (this.table.shape == 13) {
      for (let i = 0; i < positions.length; i++) {
        var circle = new fabric.Circle({
          left: this.left + i * 50 * this.scaleX - this.width / 2 + 25,
          top: this.top + (this.height * this.scaleY) / 2,
          radius: 8,
          visible: false,
          originX: "center",
          originY: "center",
          selectable: false,
        });

        chairs.push(circle);
      }
    }

    var group = new fabric.Group(chairs, {
      kind: 0, // Indicates it is a seat
      tableId: tableId,
      left: this.left,
      top: this.top,
      angle: this.angle,
      originX: "center",
      originY: "center",
      selectable: true,
      hasControls: false,
      hasBorders: false,
      lockMovementX: true,
      lockMovementY: true,
    });

    this.#followTableMovement(group);
    this.#followTableRotation(group);

    canvas.add(group);
    canvas.sendToBack(group);
    canvas.renderAll();
  }

  #calculateSeatPositions() {
    switch (this.shape) {
      case 10: // CIRCLE
        return this.#circleSeatPositions();

      case 11: // SQUARE
        return this.#squareSeatPositions();

      case 12: //RECTANGLE
        return this.#rectangleSeatPositions();

      case 13: // BAR
        return this.#barSeatPositions();
    }
  }

  #squareSeatPositions() {
    let positions = [];

    if (this.seatCount <= 4) {
      for (let i = 0; i < this.seatCount; i++) {
        if (i == 0) {
          // Top center
          positions.push({
            left: this.left,
            top: this.top - (this.height * this.scaleY) / 2,
          });
        } else if (i == 1) {
          // Bottom center
          positions.push({
            left: this.left,
            top: this.top + (this.height * this.scaleY) / 2,
          });
        } else if (i == 2) {
          // Right center
          positions.push({
            left: this.left + (this.width * this.scaleX) / 2,
            top: this.top,
          });
        } else if (i == 3) {
          // Left center
          positions.push({
            left: this.left - (this.width * this.scaleX) / 2,
            top: this.top,
          });
        }
      }
    } else if (this.seatCount >= 5 && this.seatCount <= 8) {
      positions = [
        {
          // Top Left
          left: this.left - (this.width * this.scaleX) / 5,
          top: this.top - (this.height * this.scaleY) / 2,
        },
        {
          // Top Right
          left: this.left + (this.width * this.scaleX) / 5,
          top: this.top - (this.height * this.scaleY) / 2,
        },
        {
          // Right Top
          left: this.left + (this.width * this.scaleX) / 2,
          top: this.top - (this.height * this.scaleY) / 5,
        },
        {
          // Right Bottom
          left: this.left + (this.width * this.scaleX) / 2,
          top: this.top + (this.height * this.scaleY) / 5,
        },
        {
          // Left Top
          left: this.left - (this.width * this.scaleX) / 2,
          top: this.top - (this.height * this.scaleY) / 5,
        },
        {
          // Left Bottom
          left: this.left - (this.width * this.scaleX) / 2,
          top: this.top + (this.height * this.scaleY) / 5,
        },
        {
          // Bottom Left
          left: this.left - (this.width * this.scaleX) / 5,
          top: this.top + (this.height * this.scaleY) / 2,
        },
        {
          // Bottom Right
          left: this.left + (this.width * this.scaleX) / 5,
          top: this.top + (this.height * this.scaleY) / 2,
        },
      ];
    } else {
      positions = [
        {
          // Top Left
          left: this.left - (this.width * this.scaleX) / 4,
          top: this.top - (this.height * this.scaleY) / 2,
        },
        {
          // Top Center
          left: this.left,
          top: this.top - (this.height * this.scaleY) / 2,
        },
        {
          // Top Right
          left: this.left + (this.width * this.scaleX) / 4,
          top: this.top - (this.height * this.scaleY) / 2,
        },
        {
          // Right Top
          left: this.left + (this.width * this.scaleX) / 2,
          top: this.top - (this.height * this.scaleY) / 4,
        },
        {
          // Right Center
          left: this.left + (this.width * this.scaleX) / 2,
          top: this.top,
        },
        {
          // Right Bottom
          left: this.left + (this.width * this.scaleX) / 2,
          top: this.top + (this.height * this.scaleY) / 4,
        },
        {
          // Left Top
          left: this.left - (this.width * this.scaleX) / 2,
          top: this.top - (this.height * this.scaleY) / 4,
        },
        {
          // Left Center
          left: this.left - (this.width * this.scaleX) / 2,
          top: this.top,
        },
        {
          // Left Bottom
          left: this.left - (this.width * this.scaleX) / 2,
          top: this.top + (this.height * this.scaleY) / 4,
        },
        {
          // Bottom Left
          left: this.left - (this.width * this.scaleX) / 4,
          top: this.top + (this.height * this.scaleY) / 2,
        },
        {
          // Bottom Center
          left: this.left,
          top: this.top + (this.height * this.scaleY) / 2,
        },
        {
          // Bottom Right
          left: this.left + (this.width * this.scaleX) / 4,
          top: this.top + (this.height * this.scaleY) / 2,
        },
      ];
    }

    return positions;
  }

  #circleSeatPositions() {
    let positions = [];

    if (this.seatCount <= 4) {
      for (let i = 0; i < this.seatCount; i++) {
        if (i == 0) {
          // Top center
          positions.push({
            left: this.left,
            top: this.top - this.radius * this.scaleY,
          });
        } else if (i == 1) {
          // Bottom center
          positions.push({
            left: this.left,
            top: this.top + this.radius * this.scaleY,
          });
        } else if (i == 2) {
          // Right center
          positions.push({
            left: this.left + this.radius * this.scaleX,
            top: this.top,
          });
        } else if (i == 3) {
          // Left center
          positions.push({
            left: this.left - this.radius * this.scaleX,
            top: this.top,
          });
        }
      }
    } else if (this.seatCount >= 5 && this.seatCount <= 8) {
      positions = [
        {
          // Top Left
          left: this.left - (this.radius * this.scaleX) / 2.5,
          top: this.top - this.radius * this.scaleY + 2,
        },
        {
          // Top Right
          left: this.left + (this.radius * this.scaleX) / 2.5,
          top: this.top - this.radius * this.scaleY + 2,
        },
        {
          // Right Top
          left: this.left + this.radius * this.scaleX - 2,
          top: this.top - (this.radius * this.scaleY) / 2.5,
        },
        {
          // Right Bottom
          left: this.left + this.radius * this.scaleX - 2,
          top: this.top + (this.radius * this.scaleY) / 2.5,
        },
        {
          // Left Top
          left: this.left - this.radius * this.scaleX + 2,
          top: this.top - (this.radius * this.scaleY) / 2.5,
        },
        {
          // Left Bottom
          left: this.left - this.radius * this.scaleX + 2,
          top: this.top + (this.radius * this.scaleY) / 2.5,
        },
        {
          // Bottom Left
          left: this.left - (this.radius * this.scaleX) / 2.5,
          top: this.top + this.radius * this.scaleY - 2,
        },
        {
          // Bottom Right
          left: this.left + (this.radius * this.scaleX) / 2.5,
          top: this.top + this.radius * this.scaleY - 2,
        },
      ];
    } else {
      positions = [
        {
          // Top Left
          left: this.left - (this.radius * this.scaleX) / 2,
          top: this.top - this.radius * this.scaleY,
        },
        {
          // Top Center
          left: this.left,
          top: this.top - this.radius * this.scaleY,
        },
        {
          // Top Right
          left: this.left + (this.radius * this.scaleX) / 2,
          top: this.top - this.radius * this.scaleY,
        },
        {
          // Right Top
          left: this.left + this.radius * this.scaleX,
          top: this.top - (this.radius * this.scaleY) / 2,
        },
        {
          // Right Center
          left: this.left + this.radius * this.scaleX,
          top: this.top,
        },
        {
          // Right Bottom
          left: this.left + this.radius * this.scaleX,
          top: this.top + (this.radius * this.scaleY) / 2,
        },
        {
          // Left Top
          left: this.left - this.radius * this.scaleX,
          top: this.top - (this.radius * this.scaleY) / 2,
        },
        {
          // Left Center
          left: this.left - this.radius * this.scaleX,
          top: this.top,
        },
        {
          // Left Bottom
          left: this.left - this.radius * this.scaleX,
          top: this.top + (this.radius * this.scaleY) / 2,
        },
        {
          // Bottom Left
          left: this.left - (this.radius * this.scaleX) / 2,
          top: this.top + this.radius * this.scaleY,
        },
        {
          // Bottom Center
          left: this.left,
          top: this.top + this.radius * this.scaleY,
        },
        {
          // Bottom Right
          left: this.left + (this.radius * this.scaleX) / 2,
          top: this.top + this.radius * this.scaleY,
        },
      ];
    }

    return positions;
  }

  #rectangleSeatPositions() {
    let positions = [];

    for (let i = 0; i < this.seatCount; i++) {
      if (i % 2 == 0) {
        positions.push({
          // Top
          left: this.left + i * 25 * this.scaleX - this.width / 2 + 12.5,
          top: this.top - (this.height * this.scaleY) / 2,
        });
        positions.push({
          // Bottom
          left: this.left + i * 25 * this.scaleX - this.width / 2 + 12.5,
          top: this.top + (this.height * this.scaleY) / 2,
        });
      }
    }

    return positions;
  }

  #barSeatPositions() {
    let positions = [];

    for (let i = 0; i < this.seatCount; i++) {
      positions.push({
        // Top Center
        left: this.left + i * 50 * this.scaleX - this.width / 2 + 25,
        top: this.top - (this.height * this.scaleY) / 2,
      });
    }

    return positions;
  }

  #followTableMovement(group) {
    this.table.on("moving", function () {
      // Make the square (following object) mimic the table's movement
      group.set({
        left: this.left,
        top: this.top,
      });
    });
  }

  #followTableRotation(group) {
    this.table.on("rotating", function (event) {
      group.set("angle", this.angle);
    });
  }

  // In order to place group in proper location, chairs must line up.
  #addInvisibleChairs() {
    //Square / Circle : Size 1 OR 3
    let left = this.left;
    let top = this.top;

    if (this.table.seats == 1) {
      left = this.left;
      top = this.top + (this.height * this.scaleY) / 2;
    } else if (this.table.seats == 3) {
      left = this.left - (this.width * this.scaleX) / 2;
      top = this.top;
    }

    var circle = new fabric.Circle({
      left: left,
      top: top,
      radius: 8,
      visible: false,
      originX: "center",
      originY: "center",
      selectable: false,
    });

    return circle;
  }
}
