// app/javascript/controllers/form_spinner_controller.js

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form", "spinner"];

  connect() {
    console.log("sup");
    // If the form target exists, add an event listener to handle form submission
    if (this.hasFormTarget) {
      console.log("has form");
      this.formTarget.addEventListener("submit", (event) =>
        this.showSpinner(event)
      );
    }
  }

  showSpinner(event) {
    // Prevent the default form submission to show the spinner first
    event.preventDefault();

    // Display the spinner
    this.spinnerTarget.style.display = "block";

    // Submit the form after displaying the spinner
    this.formTarget.submit();
  }
}
