import { Controller } from "stimulus"
import bootstrap from 'bootstrap/dist/js/bootstrap'

export default class extends Controller {
  static targets = ["partialToUpdate"]

  connect() {}

  updateRestaurantSchedule(event) {
    const [_data, _status, xhr] = event.detail
    this.partialToUpdateTarget.innerHTML = xhr.response

    if (_status != 'OK') { return false }

    this.triggerToast()

    var event = new CustomEvent("turbolinks:load");
    document.dispatchEvent(event);
  }

  triggerToast(status) {
    var toast = bootstrap.Toast.getOrCreateInstance(document.querySelector('.success'))
    toast.show()
  }
}
