import consumer from "./consumer";

let orderSound = null;

document.addEventListener("turbolinks:load", function () {
  const toggleButton = document.getElementById("mp3Toggle");

  if (toggleButton) {
    toggleButton.addEventListener("click", function () {
      const currentState = this.dataset.toggleMp3State;
      const newState = currentState === "off" ? "on" : "off";
      this.dataset.toggleMp3State = newState;

      if (newState === "on") {
        // Load the audio when toggled on
        const orderSoundPath =
          document.querySelector(".orders_table").dataset.orderSoundPath;
        orderSound = new Audio(orderSoundPath);
        // Preload the audio
        orderSound.load();

        this.classList.remove("btn-white");
        this.classList.add("btn-primary");
        this.innerHTML = '<i class="text-white fa-solid fa-bell"></i>';
      } else {
        // Clear the audio when toggled off
        orderSound = null;

        this.classList.remove("btn-primary");
        this.classList.add("btn-white");
        this.innerHTML = '<i class="text-primary fa-solid fa-bell-slash"></i>';
      }
    });
  }
});

consumer.subscriptions.create("OrderChannel", {
  received(data) {
    this.playOrderSound();
    this.removeEmptyRow();
    this.insertNewOrderRow(data.html);
    this.showToast();
  },

  playOrderSound() {
    const toggleButton = document.getElementById("mp3Toggle");
    if (toggleButton.dataset.toggleMp3State !== "on" || !orderSound) {
      return;
    }

    // Use the pre-loaded audio instance
    orderSound.play().catch((error) => {
      console.log("Error playing sound:", error);
    });
  },

  removeEmptyRow() {
    const emptyRow = document.querySelector(".dataTables_empty");
    if (emptyRow) {
      emptyRow.remove();
    }
  },

  insertNewOrderRow(html) {
    const tbody = document.querySelector(".orders_table tbody");
    if (tbody) {
      tbody.insertAdjacentHTML("afterbegin", html);
    }
  },

  showToast() {
    const toastElement = document.querySelector("#orderToast");
    toastElement.classList.remove("d-none");
    const toast = bootstrap.Toast.getOrCreateInstance(toastElement);
    toast.show();
  },
});
