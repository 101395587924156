/* HTML EXAMPLE:
  <div data-controller="lazy-load"
     data-lazy-load-url-value="/path/to/partial">
    <div data-lazy-load-target="output">
      <!-- Partial content will be loaded here -->
    </div>
  </div>
*/

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output"];
  static values = { url: String };

  connect() {
    this.loadPartial();
  }

  loadPartial() {
    fetch(this.urlValue, {
      method: "POST",
      headers: {
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.text())
      .then((html) => (this.outputTarget.innerHTML = html))
      .catch((error) => console.error("Error loading the partial:", error));
  }
}
