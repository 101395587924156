import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "categoryLink",
    "scrollLeftArrow",
    "scrollRightArrow",
    "scrollContainer",
    "wrapper",
  ];

  connect() {
    this.checkScrollPosition();
    this.observeCategories();
    this.addEventListeners();
  }

  disconnect() {
    this.removeEventListeners();
    this.categoryObservers.forEach((observer) => observer.disconnect());
  }

  addEventListeners() {
    window.addEventListener("resize", this.checkScrollPosition);
    this.scrollContainerTarget.addEventListener(
      "scroll",
      this.checkScrollPosition
    );
    this.observeCategories();
  }

  removeEventListeners() {
    window.removeEventListener("resize", this.checkScrollPosition);
    this.scrollContainerTarget.removeEventListener(
      "scroll",
      this.checkScrollPosition
    );
  }

  getNavHeight() {
    const navbar = document.getElementById("navbar");
    return navbar ? navbar.offsetHeight : 0;
  }

  getMenuNavHeight() {
    return this.scrollContainerTarget
      ? this.scrollContainerTarget.offsetHeight
      : 0;
  }

  arrowScrollToCategory(link) {
    this.disableHighlight = true;
    const targetSection = document.getElementById(
      `category-${link.dataset.categoryId}`
    );

    if (targetSection) {
      this.scrollPageTo(targetSection);
      this.scrollToCategoryLink(link);
    }
    setTimeout(() => (this.disableHighlight = false), 500);
  }

  scrollToCategory(event) {
    event.preventDefault();
    this.disableHighlight = true;

    const targetSection = document.getElementById(
      `category-${event.target.dataset.categoryId}`
    );

    if (targetSection) {
      this.scrollPageTo(targetSection);
    }

    this.highlightClickedCategory(event.target);
    this.scrollToElement(event.target);

    setTimeout(() => (this.disableHighlight = false), 500);
  }

  calculateBottomMarginPercent() {
    const navbar = document.getElementById("navbar");
    const menuContainerNav = document.getElementById("menu-container-nav");

    const navbarHeight = navbar ? navbar.offsetHeight : 0;
    const menuContainerNavHeight = menuContainerNav
      ? menuContainerNav.offsetHeight
      : 0;

    const totalHeight = navbarHeight + menuContainerNavHeight + 20;

    const viewportHeight = window.innerHeight;

    const bottomMarginPercent = -100 + (totalHeight / viewportHeight) * 100;
    return bottomMarginPercent;
  }

  observeCategories() {
    const bottomMarginPercent = this.calculateBottomMarginPercent();
    const observerOptions = {
      root: null,
      threshold: [0],
      rootMargin: `0px 0px ${bottomMarginPercent}% 0px`,
    };

    this.categoryObservers = this.categoryLinkTargets.map((link) => {
      const categoryId = link.dataset.categoryId;
      const targetSection = document.getElementById(`category-${categoryId}`);

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (this.disableHighlight) return;
            this.scrollToCategoryLink(link);
            this.highlightClickedCategory(link);
          }
        });
      }, observerOptions);

      observer.observe(targetSection);
      return observer;
    });
  }

  scrollToCategoryLink(link) {
    const menuCategoriesContainer = this.scrollContainerTarget;

    const linkRect = link.getBoundingClientRect();
    const containerRect = menuCategoriesContainer.getBoundingClientRect();

    const containerCenter = containerRect.width / 2;
    const linkCenter = linkRect.width / 2;
    const offsetLeft =
      linkRect.left - containerRect.left + linkCenter - containerCenter;

    const maxScrollLeft =
      menuCategoriesContainer.scrollWidth - menuCategoriesContainer.clientWidth;

    let newScrollLeft = menuCategoriesContainer.scrollLeft + offsetLeft;

    if (newScrollLeft < 0) {
      newScrollLeft = 0;
    } else if (newScrollLeft > maxScrollLeft) {
      newScrollLeft = maxScrollLeft;
    }

    menuCategoriesContainer.scrollTo({
      left: newScrollLeft,
      behavior: "smooth",
    });
  }

  scrollPageTo(targetSection) {
    const scrollPosition =
      targetSection.offsetTop -
      this.getNavHeight() -
      this.getMenuNavHeight() -
      6;

    window.scrollTo({
      top: scrollPosition,
      behavior: "smooth",
    });
  }

  scrollToElement(element) {
    const parentContainer = element.closest(".menu-categories-wrapper");
    const menuCategoriesContainer = parentContainer.querySelector(
      ".menu-categories-container"
    );

    const scrollAmount = this.calculateScrollAmount(
      menuCategoriesContainer,
      element
    );

    menuCategoriesContainer.scrollBy({
      left: scrollAmount,
      behavior: "smooth",
    });
  }

  calculateScrollAmount(container, element) {
    const containerRect = container.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();
    const containerCenter = containerRect.width / 2;
    const elementCenter = elementRect.width / 2;

    return (
      elementRect.left - containerRect.left - containerCenter + elementCenter
    );
  }

  highlightClickedCategory(target) {
    this.categoryLinkTargets.forEach((link) =>
      this.toggleCategoryActive(link, false)
    );
    this.toggleCategoryActive(target, true);
  }

  toggleCategoryActive(link, isActive) {
    link.classList.toggle("active", isActive);
    link.classList.toggle("border-bottom", isActive);
    link.classList.toggle("border-3", isActive);
    link.classList.toggle("border-primary", isActive);
    link.classList.toggle("text-primary", isActive);
  }

  scrollLeft() {
    const activeElement = this.scrollContainerTarget.querySelector(".active");

    if (activeElement) {
      const previousElement = this.getPreviousElement(activeElement);
      if (previousElement) {
        this.arrowScrollToCategory(previousElement);
        this.highlightClickedCategory(previousElement);
      }
    } else {
      console.log("No active element found.");
    }
  }

  scrollRight() {
    const activeElement = this.scrollContainerTarget.querySelector(".active");

    if (activeElement) {
      const nextElement = this.getNextElement(activeElement);
      if (nextElement) {
        this.arrowScrollToCategory(nextElement);
        this.highlightClickedCategory(nextElement);
      }
    } else {
      console.log("No active element found.");
    }
  }

  getPreviousElement(activeElement) {
    const index = this.categoryLinkTargets.indexOf(activeElement);
    if (index > 0) {
      return this.categoryLinkTargets[index - 1]; // Return the previous element
    }
    return null; // No previous element
  }

  getNextElement(activeElement) {
    const index = this.categoryLinkTargets.indexOf(activeElement);
    if (index < this.categoryLinkTargets.length - 1) {
      return this.categoryLinkTargets[index + 1]; // Return the next element
    }
    return null; // No next element
  }

  checkScrollPosition = () => {
    const { scrollLeft, scrollWidth, clientWidth } = this.scrollContainerTarget;

    if (!this.isScrollable(scrollWidth, clientWidth)) {
      this.resetScrollClasses();
      return;
    }

    this.toggleStartClass(scrollLeft);
    this.toggleEndClass(scrollLeft, clientWidth, scrollWidth);
    this.toggleScrollArrows(scrollLeft, clientWidth, scrollWidth);
  };

  isScrollable(scrollWidth, clientWidth) {
    return scrollWidth > clientWidth;
  }

  resetScrollClasses() {
    this.wrapperTarget.classList.remove("not-at-start", "not-at-end");
    this.scrollRightArrowTarget.classList.remove("show");
    this.scrollLeftArrowTarget.classList.remove("show");
  }

  toggleStartClass(scrollLeft) {
    this.wrapperTarget.classList.toggle("not-at-start", scrollLeft > 0);
  }

  toggleEndClass(scrollLeft, clientWidth, scrollWidth) {
    const leftMargin = parseFloat(
      getComputedStyle(this.wrapperTarget).marginLeft
    );
    const adjustedClientWidth = clientWidth + leftMargin;

    this.wrapperTarget.classList.toggle(
      "not-at-end",
      scrollLeft + adjustedClientWidth < scrollWidth
    );
  }

  toggleScrollArrows(scrollLeft, clientWidth, scrollWidth) {
    const leftMargin = parseFloat(
      getComputedStyle(this.wrapperTarget).marginLeft
    );
    const adjustedClientWidth = clientWidth + leftMargin;
    this.scrollLeftArrowTarget.classList.toggle("show", scrollLeft > 0);
    this.scrollRightArrowTarget.classList.toggle(
      "show",
      scrollLeft + adjustedClientWidth < scrollWidth
    );
  }

  showMenuItems(event) {
    const menuId = event.currentTarget.getAttribute("data-menu-id");
    const selectedMenuName = this.getMenuName(event);

    this.toggleVisibilityForMenu(menuId);
    this.updateDropdownButtonText(selectedMenuName);
    this.setActiveMenuItem(event.currentTarget);
    this.resetPageScroll();
    this.checkScrollPosition();
  }

  getMenuName(event) {
    return event.currentTarget.querySelector("span").innerText;
  }

  updateDropdownButtonText(menuName) {
    const dropdownButton = this.element.querySelector(".dropdown-toggle");
    dropdownButton.innerText = menuName;
  }

  setActiveMenuItem(selectedMenuItem) {
    document
      .querySelectorAll(".dropdown-menu li")
      .forEach((li) => li.classList.remove("active"));
    selectedMenuItem.classList.add("active");
  }

  toggleVisibilityForMenu(menuId) {
    document
      .querySelectorAll("[data-menu-schedule-id]")
      .forEach((element) => element.classList.add("d-none"));

    document
      .querySelectorAll(`[data-menu-schedule-id="${menuId}"]`)
      .forEach((element) => element.classList.remove("d-none"));

    document
      .querySelectorAll(".menu-categories-container .category-link")
      .forEach((item) => {
        item.classList.toggle(
          "d-none",
          item.getAttribute("data-menu-schedule-id") !== menuId
        );
      });
  }

  resetPageScroll() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
}
