import { Controller } from "stimulus"

export default class extends Controller {
  connect() {}

  remove(event) {
    const [_data, _status, xhr] = event.detail
    const element = document.querySelector("#remove_" + _data.id)
    element.remove()
  }
}
