import ApplicationController from "./application_controller.js";
import { Tooltip, Modal } from "bootstrap/dist/js/bootstrap";

export default class extends ApplicationController {
  beforeReflex(element) {
    var tooltip = Tooltip.getInstance(element);
    tooltip.hide();
  }

  finalizeReflex(element) {
    var row = document.getElementById("row-" + element.dataset["id"]);
    var tooltipTriggerList = [].slice.call(
      row.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });

    var undo = element.parentNode.querySelectorAll(".active").length <= 0;
    if (undo) {
      element.parentNode.parentNode.classList.remove(element.dataset["status"]);
    } else {
      element.parentNode.parentNode.classList.add(element.dataset["status"]);
    }

    var fullTr = $(`#row_${element.dataset["id"]}`);
    switch (element.dataset["status"]) {
      case "row--status-cancelled":
        if (!undo) {
          fullTr.fadeOut("slow", function () {});
        }
        break;
      case "row--status-completed":
        if (!undo) {
          fullTr.fadeOut("slow", function () {});
        }
        break;
      case "row--status-no_show":
        if (!undo) {
          fullTr.fadeOut("slow", function () {});
        }
        break;
    }

    row.parentNode.querySelectorAll("#statusText")[0].innerText =
      element.dataset["statustext"];

    if (element.dataset["iscomplete"] === undefined) {
      return;
    }

    //Show modal if action was not completed
    if (!parseInt(element.dataset["iscomplete"])) {
      var completedModal = new Modal(document.getElementById("completedModal"));
      completedModal.show();
    }
  }
}
