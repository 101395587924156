import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal"];

  connect() {}

  open(event) {
    event.preventDefault();
    this.modalTarget.classList.remove('hidden');
  }

  close(event) {
    event.preventDefault();
    this.modalTarget.classList.add('hidden');
  }

  escClose(event) {
    if (event.key === 'Escape') this.modalTarget.classList.add('hidden');
  }

}
