import { Controller } from "stimulus"
import bootstrap from 'bootstrap/dist/js/bootstrap'

export default class extends Controller {
  static targets = ["groupForm"]

  connect() {}

  groupForm(event) {
    const [_data, _status, xhr] = event.detail
    this.groupFormTarget.innerHTML = xhr.response
  }

  modifierFormSubmit(event) {
    const [_data, _status, xhr] = event.detail
    this.groupFormTarget.innerHTML = xhr.response

    if (_status != 'OK') { return false }

    this.triggerToast()
  }

  triggerToast() {
    var toast = bootstrap.Toast.getOrCreateInstance(document.querySelector('.success'))
    toast.show()
  }
}
