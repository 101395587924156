import { Controller } from "stimulus";
import { fabric } from "fabric";
import bootstrap from "bootstrap/dist/js/bootstrap";
import { SeatPositionCalculator } from "packs/table_management/seat_position_calculator";

export default class extends Controller {
  static targets = ["sidebar"];

  connect() {
    this.canvas = new fabric.Canvas("canvas");
    document.getElementById("canvas").fabric = this.canvas;

    this.#setupCanvas();

    this.selectedObjects = [];
    this.canvas.on("mouse:down", this.selectObject.bind(this));
  }

  #setupCanvas() {
    const htmlCanvas = document.querySelector("#canvas");
    const canvas = htmlCanvas.fabric;
    const floorID = document.querySelector("#canvas").dataset.id;

    // Get Tables
    $.ajax({
      dataType: "json",
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      url: `/restaurant/floor_plans/${floorID}/tables`,
      success: function (data) {
        let vpt = canvas.viewportTransform;
        vpt[0] = parseFloat(htmlCanvas.dataset.zoom);
        vpt[3] = parseFloat(htmlCanvas.dataset.zoom);
        vpt[4] = parseFloat(htmlCanvas.dataset.translatex);
        vpt[5] = parseFloat(htmlCanvas.dataset.translatey);
        canvas.setViewportTransform(vpt);
        canvas.requestRenderAll();

        const tables = data.floor_plan.table_configurations.map((tc) => ({
          ...tc,
          ...tc.table_attributes,
          fill: "#33AB26",
        }));

        const dividers = data.floor_plan.dividers.map((divider) => ({
          ...divider,
          selectable: false,
        }));

        // Combine tables and dividers into a single array
        const canvasObjects = tables.concat(dividers);

        const canvasData = { objects: canvasObjects };

        canvas.loadFromJSON(
          JSON.stringify(canvasData),
          canvas.renderAll.bind(canvas)
        );
        setupNumbers();
      },
      error: function (data) {
        const toast = bootstrap.Toast.getOrCreateInstance(
          document.querySelector(".error")
        );
        document
          .querySelector(".error")
          .querySelector(".toast-body").innerHTML = "An error has occurred.";
        toast.show();
      },
    });

    function setupNumbers() {
      var obj = canvas.getObjects();
      obj.forEach(function (item, i) {
        let seatPositionCalculator = new SeatPositionCalculator(
          item.id,
          item.seats,
          item.shape,
          item.left,
          item.top,
          item.height,
          item.width,
          item.radius,
          item.scaleX,
          item.scaleY,
          item.angle,
          item
        );

        seatPositionCalculator.addSeatsToCanvas();
        if (!item.number) return;
        var text = new fabric.Text(item.number.toString(), {
          fontSize: 16,
          originX: "center",
          originY: "center",
          fill: "#000",
          selectable: false,
        });
        console.log(item.table_group);
        var bg = new fabric.Rect({
          fill: "#d8d9da",
          scaleY: 0.5,
          originX: "center",
          originY: "center",
          rx: 5,
          ry: 5,
          width: text.width + 5,
          height: 40,
          selectable: false,
        });

        if (item.table_group) {
          bg.set({ stroke: "#8bcce4", strokeWidth: 2 });
        }

        var angle = item.angle % 360; // Normalize angle to be within 0-359 degrees

        // Determine which algorithm to use based on the rotation angle
        if (angle === 90 || angle === 270) {
          // Use height-based positioning for 90 or 270 degrees
          var group = new fabric.Group([bg, text], {
            left: item.left - (item.height * item.scaleX) / 2 - 10,
            top: item.top - (item.width * item.scaleY) / 2 - 10,
            tableId: item.id,
            selectable: true,
            hasControls: false,
            hasBorders: false,
            lockMovementX: true,
            lockMovementY: true,
          });
        } else {
          // Use width-based positioning for 0, 180, or other angles
          var group = new fabric.Group([bg, text], {
            left: item.left - (item.width * item.scaleX) / 2 - 10,
            top: item.top - (item.height * item.scaleY) / 2 - 10,
            tableId: item.id,
            selectable: true,
            hasControls: false,
            hasBorders: false,
            lockMovementX: true,
            lockMovementY: true,
          });
        }

        canvas.add(group);

        item.on("moving", function () {
          // Make the table number follow table when moving
          var angle = item.angle % 360; // Normalize angle to be within 0-359 degrees

          // Determine which algorithm to use based on the rotation angle
          if (angle === 90 || angle === 270) {
            // Use height-based positioning for 90 or 270 degrees
            group.set({
              left: item.left - (item.height * item.scaleX) / 2 - 10,
              top: item.top - (item.width * item.scaleY) / 2 - 10,
            });
          } else {
            // Use width-based positioning for 0, 180, or other angles
            group.set({
              left: item.left - (item.width * item.scaleX) / 2 - 10,
              top: item.top - (item.height * item.scaleY) / 2 - 10,
            });
          }
          canvas.renderAll();
        });
      });
    }
  }

  selectObject(event) {
    const object = event.target;
    if (object && object.kind == "table") {
      if (!this.selectedObjects.includes(object.table_id)) {
        this.selectedObjects.push(object.table_id);
        object.set("fill", "#f5f542"); // Indicate selection
      } else {
        this.selectedObjects = this.selectedObjects.filter(
          (objId) => objId !== object.table_id
        );
        object.set("fill", "#33AB26");
      }
      this.canvas.renderAll(); // Ensure the canvas updates after changes
    }
  }

  sendSelectedIds() {
    const url = `/restaurant/floor_plans/${this.element.dataset.floorPlanId}/table_groups`;

    // Get the value of the group number input field
    const groupNumberInput = document.getElementById("group-number-input");
    const groupNumber = groupNumberInput.value.trim();

    // Check if the group number field is empty
    if (groupNumber === "") {
      const toast = bootstrap.Toast.getOrCreateInstance(
        document.querySelector(".error")
      );
      document.querySelector(".error").querySelector(".toast-body").innerHTML =
        "Please fill out a group number and resubmit.";
      toast.show();

      groupNumberInput.classList.add("is-invalid");

      return;
    }

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({
        table_group: {
          number: groupNumber, // Include the group number in the table_group params
          restaurant_id: this.restaurantId, // Include the restaurant_id in the table_group params
        },
        object_ids: this.selectedObjects,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          // Handle validation errors
          return response.json().then((data) => {
            throw new Error(data.error || "Something went wrong");
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.table_group_html) {
          // Reset fill color for all selected tables
          this.selectedObjects.forEach((id) => {
            const object = this.canvas
              .getObjects()
              .find((obj) => obj.table_id === id);
            if (object) {
              object.set("fill", "#33AB26"); // Reset to default color
            }
          });
          // Insert the rendered HTML into the DOM
          document
            .getElementById("table-group-list")
            .insertAdjacentHTML("beforeend", data.table_group_html);
        }

        // Deselect all objects
        this.canvas.discardActiveObject();
        this.canvas.renderAll(); // Update the canvas

        this.reinstantiateTooltips();
        const groupNumberInput = document.getElementById("group-number-input");
        groupNumberInput.classList.remove("is-invalid");
        groupNumberInput.value = "";

        // Clear the selection after sending
        this.selectedObjects = [];

        // Show success toast
        this.successToast();
      })
      .catch((error) => {
        this.showErrorToast(error.message || "An error has occurred.");
      });
  }

  linkTablesToExistingGroup(event) {
    const floorPlanId = this.element.dataset.floorPlanId;
    const existingGroupId = event.currentTarget.dataset.tableGroupId;
    const url = `/restaurant/floor_plans/${floorPlanId}/table_groups/${existingGroupId}/link_tables`;

    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({
        object_ids: this.selectedObjects,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          // Handle validation errors
          return response.json().then((data) => {
            throw new Error(data.error || "Something went wrong");
          });
        }
        return response.json();
      })
      .then((data) => {
        // Update the existing group or perform any additional logic
        if (data.table_group_html) {
          // Reset fill color for all selected tables
          this.selectedObjects.forEach((id) => {
            const object = this.canvas
              .getObjects()
              .find((obj) => obj.table_id === id);
            if (object) {
              object.set("fill", "#33AB26"); // Reset to default color
            }
          });
          // Insert the rendered HTML into the DOM
          document.getElementById(`list-${data.id}`).innerHTML =
            data.table_group_html;
        }

        // Deselect all objects
        this.canvas.discardActiveObject();
        this.canvas.renderAll(); // Update the canvas

        // Clear the selection after sending
        this.selectedObjects = [];

        this.reinstantiateTooltips();

        // Show success toast
        this.successToast();

        // Additional logic for success of linking tables
      })
      .catch((error) => {
        this.showErrorToast(error.message || "An error has occurred.");
      });
  }

  deleteGroup(event) {
    const groupId = event.detail[0].id;
    const groupElement = document.getElementById(`list-${groupId}`);
    if (groupElement) {
      this.hideTooltips();
      groupElement.remove();
    }

    this.successToast();
  }

  removeTable(event) {
    const tableId = event.detail[0].id;
    const tableElement = document.getElementById(`table-${tableId}`);
    if (tableElement) {
      this.hideTooltips();
      tableElement.remove();
      document.querySelector(`#seats-${event.detail[0].group_id}`).innerHTML =
        event.detail[0].seat_count;
    }

    this.successToast();
  }

  successToast() {
    bootstrap.Toast.getOrCreateInstance(
      document.querySelector(".success")
    ).show();
  }

  showErrorToast(message = "An error has occurred.") {
    const toast = bootstrap.Toast.getOrCreateInstance(
      document.querySelector(".error")
    );
    document.querySelector(".error").querySelector(".toast-body").innerHTML =
      message;
    toast.show();
  }

  reinstantiateTooltips() {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
  }

  hideTooltips() {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      bootstrap.Tooltip.getInstance(tooltipTriggerEl).hide();
    });
  }
}
