import { Controller } from "stimulus";
import { fabric } from "fabric";
import { TableTimers } from "packs/table_management/table_timers";
import { TableStacking } from "packs/table_management/table_stacking";

export default class extends Controller {
  connect() {}

  error(event) {
    const [_data, _status] = event.detail;

    var msg = "An unexpected error has occurred.";
    if (_data.message) msg = _data.message;
    const toast = bootstrap.Toast.getOrCreateInstance(
      document.querySelector(".error")
    );
    document.querySelector(".error").querySelector(".toast-body").innerHTML =
      msg;
    toast.show();
  }

  statusUpdated(event) {
    const [data, _status] = event.detail;
    const canvas = document.querySelector("#canvas").fabric;

    bootstrap.Modal.getOrCreateInstance(
      document.querySelector("#tableModal")
    ).hide();
    bootstrap.Toast.getOrCreateInstance(
      document.querySelector(".success")
    ).show();

    const fabricObjects = canvas.getObjects();
    fabricObjects.forEach(function (object) {
      if (object.kind == "table" && data.table_ids.includes(object.table_id)) {
        object.set("fill", data.table_color);
      }
    });

    if (data.table_timers) {
      let tableTimer = new TableTimers(data.table_timers);
      tableTimer.removeTimers();
      tableTimer.addTimers();
    }

    if (data.table_stacks) {
      let tableStacker = new TableStacking(data.table_stacks);
      tableStacker.remove();
      tableStacker.stack();
    }

    canvas.renderAll();

    if (data.destroy) {
      const floorId = document.querySelector("#canvas").dataset.id;
      let openTab = "reservations";
      if (document.querySelector("#all").dataset.open == "true")
        openTab = "load_all";
      if (document.querySelector("#waitlist").dataset.open == "true")
        openTab = "waiting_list_entries";

      $.ajax({
        type: "GET",
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "X-CSRF-Token",
            $('meta[name="csrf-token"]').attr("content")
          );
        },
        url: `/restaurant/floor_plans/${openTab}?floor_plan_id=${floorId}`,
        success: function (data) {
          if (openTab == "reservations") {
            document.querySelector("#reservations").innerHTML = data;
          } else if (openTab == "waiting_list_entries") {
            document.querySelector("#waitlist").innerHTML = data;
          } else {
            document.querySelector("#all").innerHTML = data;
          }

          // Reset Drag Listener
          const htmlCanvas = document.querySelector("#canvas");
          var rows = document.querySelectorAll(".draggable-row");

          function handleDragStart(event) {
            htmlCanvas.dataset.currentObj = event.target.dataset.id;
            htmlCanvas.dataset.objType = event.target.dataset.type;
          }

          [].forEach.call(rows, function (row) {
            row.addEventListener("dragstart", handleDragStart, false);
          });

          var tooltipTriggerList = [].slice.call(
            document.querySelectorAll('[data-bs-toggle="popover"]')
          );
          var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Popover(tooltipTriggerEl);
          });
        },
        error: function (data) {
          console.log(data);
        },
      });
    }
  }

  #getTable(table_id) {
    const canvas = document.querySelector("#canvas").fabric;
    const objects = canvas.getObjects();
    var object;
    for (let i = 0; i < objects.length; i++) {
      if (objects[i].id && objects[i].table_id == table_id) {
        object = objects[i];
        break;
      }
    }
    return object;
  }
}
