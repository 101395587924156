import { Controller } from "stimulus";
import bootstrap from "bootstrap/dist/js/bootstrap";

export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.showCTA();
    }, 2000);
  }

  showCTA() {
    const modalElement = new bootstrap.Modal(this.element);
    modalElement.show();
  }
}
