// app/javascript/controllers/schedule_demo_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["cta"];

  connect() {
    window.addEventListener("scroll", this.showCTA.bind(this));
    window.addEventListener("resize", this.showCTA.bind(this));
    this.showCTA();
  }

  disconnect() {
    window.removeEventListener("scroll", this.showCTA.bind(this));
    window.removeEventListener("resize", this.showCTA.bind(this));
  }

  showCTA() {
    if (this.ctaTarget.dataset.demoHidden == "true") return;
    const { ctaTarget } = this;
    const isScrolledToMiddle =
      window.scrollY >=
      (document.documentElement.scrollHeight - window.innerHeight) / 2;
    const shouldShow =
      isScrolledToMiddle && ctaTarget.classList.contains("d-none");

    if (shouldShow) {
      ctaTarget.classList.remove("d-none", "scroll-down");
      ctaTarget.classList.add("scroll-up");
    } else if (!isScrolledToMiddle && !ctaTarget.classList.contains("d-none")) {
      ctaTarget.classList.remove("scroll-up");
      ctaTarget.classList.add("scroll-down");
      setTimeout(() => ctaTarget.classList.add("d-none"), 500);
    }
  }

  hideCTA() {
    this.ctaTarget.classList.remove("scroll-up");
    this.ctaTarget.classList.add("scroll-down");
    setTimeout(() => {
      this.ctaTarget.classList.add("d-none");
      this.ctaTarget.dataset.demoHidden = "true";
    }, 500);
  }
}
