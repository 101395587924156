import { Controller } from "stimulus"
import Chart from 'chart.js/auto'

export default class extends Controller {
  static targets = ["canvas"]

  connect() {
    if (this.chartExists()) return;

    var lineCharts = document.querySelectorAll(".lineChart"), i;
    var multiLineCharts = document.querySelectorAll(".multiLineChart");
    var revenueMultiLineCharts = document.querySelectorAll(".revenueMultiLineChart");
    var pieCharts = document.querySelectorAll(".pieChart");

    for (i = 0; i < lineCharts.length; ++i) {
      this.setUpLineChart(lineCharts[i]);
    }

    for (i = 0; i < multiLineCharts.length; ++i) {
      this.setUpMultiLineChart(multiLineCharts[i]);
    }

    for (i = 0; i < revenueMultiLineCharts.length; ++i) {
      this.setUpRevenueMultiLineChart(revenueMultiLineCharts[i]);
    }

    for (i = 0; i < pieCharts.length; ++i) {
      this.setUpPieChart(pieCharts[i]);
    }
  }

  chartExists() {
    const chart = Chart.getChart(document.querySelector('canvas'))
    return chart != null
  }

  updateLineChart(event) {
    const [_data, _status, xhr] = event.detail

    this.canvasTarget.innerHTML = xhr.response
    this.setUpLineChart(this.canvasTarget.firstChild);
  }

  updateMultiLineChart(event) {
    const [_data, _status, xhr] = event.detail

    this.canvasTarget.innerHTML = xhr.response
    this.setUpMultiLineChart(this.canvasTarget.firstChild);
  }

  updatePieChart(event) {
    const [_data, _status, xhr] = event.detail

    this.canvasTarget.innerHTML = xhr.response
    this.setUpPieChart(this.canvasTarget.firstChild);
  }

  setUpLineChart(ctx, label) {
    var myLineChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: JSON.parse(ctx.dataset.labels),
        datasets: [{
          label: ctx.dataset.label,
          lineTension: 0.3,
          backgroundColor: "rgba(78, 115, 223, 0.05)",
          borderColor: '#e74a3b',
          pointRadius: 3,
          pointBackgroundColor: '#e74a3b',
          pointBorderColor: '#e74a3b',
          pointHoverRadius: 3,
          pointHoverBackgroundColor: '#e74a3b',
          pointHoverBorderColor: '#e74a3b',
          pointHitRadius: 10,
          pointBorderWidth: 2,
          data: JSON.parse(ctx.dataset.data),
        }],
      },
      options: {
        plugins: {
          legend: {
            display: false
          },
          tooltips: {
            backgroundColor: 'rgba(215, 34, 34, 0.8)',
            bodyFontColor: "#858796",
            titleMarginBottom: 10,
            titleFontColor: '#6e707e',
            titleFontSize: 14,
            borderColor: '#dddfeb',
            borderWidth: 1,
            xPadding: 15,
            yPadding: 15,
            displayColors: true,
            intersect: false,
            mode: 'index',
            caretPadding: 10,
            callbacks: {
              label: function(tooltipItem, chart) {
                var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
                return datasetLabel + ": " + this.number_format(tooltipItem.yLabel);
              }
            }
          }
        },
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 10,
            right: 25,
            top: 25,
            bottom: 0
          }
        },
        scales: {
          xAxes: {
            time: {
              unit: 'date'
            },
            gridLines: {
              display: false,
              drawBorder: false
            },
            ticks: {
              maxTicksLimit: 7
            }
          },
          yAxes: {
            ticks: {
              maxTicksLimit: 5,
              padding: 10,
            },
            gridLines: {
              color: "rgb(234, 236, 244)",
              zeroLineColor: "rgb(234, 236, 244)",
              drawBorder: false,
              borderDash: [2],
              zeroLineBorderDash: [2]
            }
          },
        },
      }
    });
  }

  buildDatasets (all_data, lineColors) { 
    return Object.keys(all_data).map((key, i) => {
    const dataArray = all_data[key];
    const formattedKey = key.replace(/_/g, ' ')
                            .split(' ')
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(' ');
      return {
        label: formattedKey,
        lineTension: 0.3,
        backgroundColor: "rgba(78, 115, 223, 0.05)",
        borderColor: lineColors[i],
        pointRadius: 3,
        pointBackgroundColor: lineColors[i],
        pointBorderColor: lineColors[i],
        pointHoverRadius: 3,
        pointHoverBackgroundColor: lineColors[i],
        pointHoverBorderColor: lineColors[i],
        pointHitRadius: 10,
        pointBorderWidth: 2,
        data: dataArray
      };
    })
  }

  setUpMultiLineChart(ctx) {
    const allData = JSON.parse(ctx.dataset.allData)
    const lineColors = JSON.parse(ctx.dataset.lineColors)

    var myLineChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: JSON.parse(ctx.dataset.labels),
        datasets: this.buildDatasets(allData, lineColors)
      },
      options: {
        plugins: {
          legend: {
            display: false
          },
          tooltips: {
            backgroundColor: 'rgba(215, 34, 34, 0.8)',
            bodyFontColor: "#858796",
            titleMarginBottom: 10,
            titleFontColor: '#6e707e',
            titleFontSize: 14,
            borderColor: '#dddfeb',
            borderWidth: 1,
            xPadding: 15,
            yPadding: 15,
            displayColors: true,
            intersect: false,
            mode: 'index',
            caretPadding: 10,
            callbacks: {
              label: function(tooltipItem, chart) {
                var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
                return datasetLabel + ": " + this.number_format(tooltipItem.yLabel);
              }
            }
          }
        },
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 10,
            right: 25,
            top: 25,
            bottom: 0
          }
        },
        scales: {
          xAxes: {
            time: {
              unit: 'date'
            },
            gridLines: {
              display: false,
              drawBorder: false
            },
            ticks: {
              maxTicksLimit: 7
            }
          },
          yAxes: {
            ticks: {
              maxTicksLimit: 5,
              padding: 10,
            },
            gridLines: {
              color: "rgb(234, 236, 244)",
              zeroLineColor: "rgb(234, 236, 244)",
              drawBorder: false,
              borderDash: [2],
              zeroLineBorderDash: [2]
            }
          },
        },
      }
    });
  }

  setUpRevenueMultiLineChart(ctx, label) {
    var myLineChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: JSON.parse(ctx.dataset.labels),
        datasets: [
          {
            label: 'Monthly Fees',
            lineTension: 0.3,
            backgroundColor: "rgba(78, 115, 223, 0.05)",
            borderColor: '#DC2F02',
            pointRadius: 3,
            pointBackgroundColor: '#DC2F02',
            pointBorderColor: '#DC2F02',
            pointHoverRadius: 3,
            pointHoverBackgroundColor: '#DC2F02',
            pointHoverBorderColor: '#DC2F02',
            pointHitRadius: 10,
            pointBorderWidth: 2,
            data: JSON.parse(ctx.dataset.fees)
          },
          {
            label: 'Tickets',
            lineTension: 0.3,
            backgroundColor: "rgba(78, 115, 223, 0.05)",
            borderColor: '#f6c23e',
            pointRadius: 3,
            pointBackgroundColor: '#f6c23e',
            pointBorderColor: '#f6c23e',
            pointHoverRadius: 3,
            pointHoverBackgroundColor: '#f6c23e',
            pointHoverBorderColor: '#f6c23e',
            pointHitRadius: 10,
            pointBorderWidth: 2,
            data: JSON.parse(ctx.dataset.tickets)
          },
          {
            label: 'Gift Cards',
            lineTension: 0.3,
            backgroundColor: "rgba(78, 115, 223, 0.05)",
            borderColor: '#1cc88a',
            pointRadius: 3,
            pointBackgroundColor: '#1cc88a',
            pointBorderColor: '#1cc88a',
            pointHoverRadius: 3,
            pointHoverBackgroundColor: '#1cc88a',
            pointHoverBorderColor: '#1cc88a',
            pointHitRadius: 10,
            pointBorderWidth: 2,
            data: JSON.parse(ctx.dataset.giftcards)
          },
          {
            label: 'Orders',
            lineTension: 0.3,
            backgroundColor: "rgba(78, 115, 223, 0.05)",
            borderColor: '#36b9cc',
            pointRadius: 3,
            pointBackgroundColor: '#36b9cc',
            pointBorderColor: '#36b9cc',
            pointHoverRadius: 3,
            pointHoverBackgroundColor: '#36b9cc',
            pointHoverBorderColor: '#36b9cc',
            pointHitRadius: 10,
            pointBorderWidth: 2,
            data: JSON.parse(ctx.dataset.orders)
          },
          {
            label: 'Total',
            lineTension: 0.3,
            backgroundColor: "rgba(78, 115, 223, 0.05)",
            borderColor: '#858796',
            pointRadius: 3,
            pointBackgroundColor: '#858796',
            pointBorderColor: '#858796',
            pointHoverRadius: 3,
            pointHoverBackgroundColor: '#858796',
            pointHoverBorderColor: '#858796',
            pointHitRadius: 10,
            pointBorderWidth: 2,
            data: JSON.parse(ctx.dataset.totals)
          }
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false
          },
          tooltips: {
            backgroundColor: 'rgba(215, 34, 34, 0.8)',
            bodyFontColor: "#858796",
            titleMarginBottom: 10,
            titleFontColor: '#6e707e',
            titleFontSize: 14,
            borderColor: '#dddfeb',
            borderWidth: 1,
            xPadding: 15,
            yPadding: 15,
            displayColors: true,
            intersect: false,
            mode: 'index',
            caretPadding: 10,
            callbacks: {
              label: function(tooltipItem, chart) {
                var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
                return datasetLabel + ": " + this.number_format(tooltipItem.yLabel);
              }
            }
          }
        },
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 10,
            right: 25,
            top: 25,
            bottom: 0
          }
        },
        scales: {
          xAxes: {
            time: {
              unit: 'date'
            },
            gridLines: {
              display: false,
              drawBorder: false
            },
            ticks: {
              maxTicksLimit: 7
            }
          },
          yAxes: {
            ticks: {
              maxTicksLimit: 5,
              padding: 10,
            },
            gridLines: {
              color: "rgb(234, 236, 244)",
              zeroLineColor: "rgb(234, 236, 244)",
              drawBorder: false,
              borderDash: [2],
              zeroLineBorderDash: [2]
            }
          },
        },
      }
    });
  }

  setUpPieChart(ctz) {
    var myPieChart = new Chart(ctz, {
      type: 'doughnut',
      data: {
        labels: JSON.parse(ctz.dataset.labels),
        datasets: [{
          data: JSON.parse(ctz.dataset.data),
          // If colors are changed, update application helper method.
          backgroundColor: ['#e74a3b', '#f6c23e', '#1cc88a', '#36b9cc', '#e74a3b'],
          hoverBackgroundColor: ['#e74a3b', '#f6c23e', '#1cc88a', '#36b9cc', '#e74a3b'],
          hoverBorderColor: "rgba(234, 236, 244, 1)",
        }],
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        },
        tooltips: {
          backgroundColor: "rgb(255,255,255)",
          bodyFontColor: "#858796",
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          caretPadding: 10,
        },
        cutoutPercentage: 80,
      },
    });
  }

  number_format(number, decimals, dec_point, thousands_sep) {
    // *     example: number_format(1234.56, 2, ',', ' ');
    // *     return: '1 234,56'
    number = (number + '').replace(',', '').replace(' ', '');
    var n = !isFinite(+number) ? 0 : +number,
      prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
      sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
      dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
      s = '',
      toFixedFix = function(n, prec) {
        var k = Math.pow(10, prec);
        return '' + Math.round(n * k) / k;
      };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
      s[1] = s[1] || '';
      s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
  }
}
