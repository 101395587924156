import ApplicationController from './application_controller.js'

export default class extends ApplicationController {
  finalizeReflex(element) {
    flatpickr(".flatpickr", {
      altInput: true,
      altFormat: "F j, Y",
      dateFormat: "Y-m-d",
      disableMobile: "true"
    })
  }
}
