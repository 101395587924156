import consumer from "./consumer";

document.addEventListener("turbolinks:load", function () {
  // Get the current path from the URL
  const currentPath = window.location.pathname;
  const urlParams = new URLSearchParams(window.location.search);
  const date = urlParams.get("date");

  // Check if the current path matches /restaurant/reservations
  if (currentPath === "/restaurant/table_sheets") {
    consumer.subscriptions.create("TableSheetChannel", {
      received(data) {
        if (
          (!date && data.today !== data.reservation_on) ||
          (date && date !== data.reservation_on)
        ) {
          return;
        }

        const tableRowElement = document.querySelector(
          `#table_row_${data.tableId}`
        );

        if (tableRowElement) {
          tableRowElement.innerHTML = data.html;
        }
      },
    });
  }
});
