import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["yearSelect", "monthSelect"];

  updateMonths() {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // Months are 0-based
    const selectedYear = parseInt(this.yearSelectTarget.value, 10);

    // Determine the month range
    const monthRange =
      selectedYear === currentYear
        ? Array.from({ length: currentMonth }, (_, i) => i + 1) // [1..currentMonth]
        : Array.from({ length: 12 }, (_, i) => i + 1); // [1..12]

    // Update the month select options
    this.monthSelectTarget.innerHTML = monthRange
      .map((month) => `<option value="${month}">${month}</option>`)
      .join("");
  }
}
