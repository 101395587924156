import { Controller } from "stimulus";
import { fabric } from "fabric";
import bootstrap from "bootstrap/dist/js/bootstrap";

export default class extends Controller {
  connect() {
    const canvas = new fabric.Canvas("canvas");
    document.getElementById("canvas").fabric = canvas;

    this.#canvasListeners();
  }

  #canvasListeners() {
    const canvas = document.querySelector("#canvas").fabric;
    const tableForm = document.querySelector(".tableForm");
    const bsCollapse = bootstrap.Collapse.getOrCreateInstance(
      document.getElementById("properties-collapse"),
      {
        toggle: false,
      }
    );

    canvas.on("mouse:down", function (options) {
      if (options.target) {
        var selected = options.target;

        document.querySelector("#tableNumber").value = selected.number;
        document.querySelector("#seats").value = selected.seats;

        tableForm.classList.remove("d-none");
        bsCollapse.show();
      }
    });

    canvas.on("selection:cleared", function (options) {
      bsCollapse.hide();
      tableForm.reset();
      tableForm.classList.add("d-none");
    });

    //Snap to Grid
    canvas.on("object:moving", function (options) {
      const grid = 10;
      options.target.set({
        left: Math.round(options.target.left / grid) * grid,
        top: Math.round(options.target.top / grid) * grid,
      });
    });

    canvas.on("object:rotating", function (event) {
      var obj = event.target;
      // Calculate the nearest 15 degree angle
      var angle = Math.round(obj.angle / 15) * 15;
      // Set the object's angle to the nearest 90 degrees
      obj.set("angle", angle);
    });
  }

  saveFloorPlan() {
    const floorID = document.querySelector("#canvas").dataset.id;
    const htmlCanvas = document.querySelector("#canvas");
    const canvas = htmlCanvas.fabric;

    canvas.discardActiveObject().renderAll();
    var json = canvas.toJSON([
      "floor_plan_id",
      "number",
      "seats",
      "kind",
      "shape",
    ]);

    const table_configurations = json.objects
      .filter((object) => object.kind == "table" || object.kind == "bar")
      .map((object) => ({
        angle: object.angle,
        backgroundColor: object.backgroundColor,
        fill: object.fill,
        fillRule: object.fillRule,
        flipX: object.flipX,
        flipY: object.flipY,
        globalCompositeOperation: object.globalCompositeOperation,
        height: object.height,
        left: object.left,
        opacity: object.opacity,
        originX: object.originX,
        originY: object.originY,
        paintFirst: object.paintFirst,
        rx: object.rx,
        ry: object.ry,
        scaleX: object.scaleX,
        scaleY: object.scaleY,
        shadow: object.shadow,
        skewX: object.skewX,
        skewY: object.skewY,
        stroke: object.stroke,
        strokeDashArray: object.strokeDashArray,
        strokeDashOffset: object.strokeDashOffset,
        strokeLineCap: object.strokeLineCap,
        strokeLineJoin: object.strokeLineJoin,
        strokeMiterLimit: object.strokeMiterLimit,
        strokeUniform: object.strokeUniform,
        strokeWidth: object.strokeWidth,
        top: object.top,
        type: object.type,
        version: object.version,
        visible: object.visible,
        width: object.width,
        radius: object.radius,
        startAngle: object.startAngle,
        endAngle: object.endAngle,
        lockScalingX: object.lockScalingX,
        lockScalingY: object.lockScalingY,
        table_attributes: {
          section_id: object.section_id,
          kind: object.kind,
          shape: object.shape,
          number: object.number,
          seats: object.seats,
          status: object.status,
        },
      }));
    console.log(table_configurations);

    $.ajax({
      dataType: "json",
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      type: "POST",
      url: "/restaurant/floor_plans",
      data: {
        floor_plan: {
          offset_x: htmlCanvas.dataset.offsetx,
          offset_y: htmlCanvas.dataset.offsety,
          zoom: htmlCanvas.dataset.zoom,
          translate_x: htmlCanvas.dataset.translatex,
          translate_y: htmlCanvas.dataset.translatey,
          table_configurations_attributes: table_configurations,
          dividers_attributes: json.objects.filter(
            (object) => object.kind == "divider"
          ),
        },
      },
      success: function (data) {
        window.location.href = data.redirect;
      },
      error: function (data) {
        const toast = bootstrap.Toast.getOrCreateInstance(
          document.querySelector(".error")
        );
        document
          .querySelector(".error")
          .querySelector(".toast-body").innerHTML = data.responseJSON.message;
        toast.show();
      },
    });
  }

  saveProperties(event) {
    event.preventDefault();
    const canvas = document.getElementById("canvas").fabric;
    const activeObject = canvas.getActiveObject();
    const tableForm = document.querySelector(".tableForm");
    const bsCollapse = bootstrap.Collapse.getOrCreateInstance(
      document.getElementById("properties-collapse")
    );

    let numberGroup = findGroupByTableNumber(activeObject.number);
    canvas.remove(numberGroup);

    activeObject.number = document.querySelector("#tableNumber").value;
    activeObject.seats = document.querySelector("#seats").value;
    addTableNumber(activeObject);
    bsCollapse.hide();
    tableForm.reset();

    function addTableNumber(item) {
      if (!item.number) return;
      var text = new fabric.Text(item.number.toString(), {
        fontSize: 16,
        originX: "center",
        originY: "center",
        fill: "#000",
        selectable: false,
      });

      var bg = new fabric.Rect({
        fill: "rgb(229 231 235)",
        scaleY: 0.5,
        originX: "center",
        originY: "center",
        rx: 5,
        ry: 5,
        width: text.width + 5,
        height: 40,
        selectable: false,
      });

      var group = new fabric.Group([bg, text], {
        left: item.left - (item.width * item.scaleX) / 2 - 10,
        top: item.top - (item.height * item.scaleY) / 2 - 10,
        tableNumber: item.number,
      });

      canvas.add(group);

      item.on("moving", function () {
        // Make the square (following object) mimic the circle's movement
        group.set({
          left: item.left - (item.width * item.scaleX) / 2 - 10,
          top: item.top - (item.height * item.scaleY) / 2 - 10,
        });
      });
    }

    function findGroupByTableNumber(tableNumber) {
      let foundGroup = null;

      canvas.forEachObject(function (object) {
        if (object.type === "group" && object.tableNumber === tableNumber) {
          foundGroup = object;
          return false; // Break the loop
        }
      });

      return foundGroup;
    }
  }
}
