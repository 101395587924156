import consumer from "./consumer";
import { Tooltip, Popover } from "bootstrap/dist/js/bootstrap";

document.addEventListener("turbolinks:load", function () {
  const currentPath = window.location.pathname;
  const urlParams = new URLSearchParams(window.location.search);
  const date = urlParams.get("date");

  if (
    currentPath === "/restaurant/reservations" ||
    currentPath === "/restaurant/reservations/on_date"
  ) {
    consumer.subscriptions.create("ReservationChannel", {
      received(data) {
        console.log("Date: ", date);
        console.log("data.reservation_on: ", data.reservation_on);
        if (
          (!date && data.today !== data.reservation_on) ||
          (date && date !== data.reservation_on)
        ) {
          console.log("returning..");
          return;
        }

        // Remove the "empty" row if it exists
        const emptyRow = document.querySelector(".dataTables_empty");
        if (emptyRow) {
          emptyRow.remove();
        }

        // Prepend the new reservation row
        const tbody = document.querySelector("#tableBody");
        const firstRow = tbody.querySelector("tr:first-child");
        console.log("First Row: ", firstRow);
        if (firstRow) {
          firstRow.insertAdjacentHTML("beforebegin", data.html);
        }

        // Initialize Bootstrap tooltips for the new row
        var row = document
          .getElementsByTagName("tbody")[0]
          .getElementsByTagName("tr")[0];
        var tooltipTriggerList = [].slice.call(
          row.querySelectorAll('[data-bs-toggle="tooltip"]')
        );
        tooltipTriggerList.map(function (tooltipTriggerEl) {
          return new Tooltip(tooltipTriggerEl);
        });

        // Initialize Bootstrap popovers for the new row
        var popoverTriggerList = [].slice.call(
          row.querySelectorAll('[data-bs-toggle="popover"]')
        );
        popoverTriggerList.map(function (popoverTriggerEl) {
          return new Popover(popoverTriggerEl);
        });
      },
    });
  }
});
