import consumer from "./consumer";

consumer.subscriptions.create("WaitingListEntryChannel", {
  connected() {},

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel

    let waiting_list_entry = document.querySelector(`#obj${data.id}`);
    if (waiting_list_entry)
      waiting_list_entry.classList.add("line-through-div");
  },
});
