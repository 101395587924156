export class TableStacking {
  constructor(tablesHash) {
    this.tablesHash = tablesHash;
  }

  stack() {
    const canvas = document.querySelector("#canvas").fabric;
    const fabricObjects = canvas.getObjects();
    const tablesHash = this.tablesHash;

    fabricObjects.forEach(function (object) {
      const reservationDetails = tablesHash[object.table_id];
      if (object.kind === "table" && tablesHash[object.table_id]) {
        for (let i = 0; i < reservationDetails.length; i++) {
          var text = new fabric.Text(reservationDetails[i]["text"], {
            fontSize: 16,
            originX: "center",
            originY: "center",
            fill: "#000",
            selectable: false,
          });

          var bg = new fabric.Rect({
            fill: reservationDetails[i]["bg_fill"],
            scaleY: 0.5,
            originX: "center",
            originY: "center",
            rx: 5,
            ry: 5,
            width: text.width + 5,
            height: 40,
            selectable: false,
          });

          var angle = object.angle % 360; // Normalize angle to be within 0-359 degrees

          // Determine which algorithm to use based on the rotation angle
          if (angle === 90 || angle === 270) {
            // Use height-based positioning for 90 or 270 degrees
            var group = new fabric.Group([bg, text], {
              left: object.left - (object.height * object.scaleX) / 2 - 55,
              top:
                object.top - (object.height * object.scaleY) / 2 + 20 * (i + 1),
              tableId: object.table_id,
              stack: true,
              selectable: false,
            });
          } else {
            // Use width-based positioning for 0, 180, or other angles
            var group = new fabric.Group([bg, text], {
              left: object.left - (object.width * object.scaleX) / 2 - 55,
              top:
                object.top - (object.height * object.scaleY) / 2 + 20 * (i + 1),
              tableId: object.table_id,
              stack: true,
              selectable: false,
            });
          }

          canvas.add(group);
        }
      }
    });

    canvas.renderAll();
  }

  remove() {
    const canvas = document.querySelector("#canvas").fabric;
    canvas.forEachObject((obj) => {
      if (obj.type === "group" && obj.stack === true) {
        canvas.remove(obj); // Remove the group from the canvas
      }
    });

    canvas.renderAll();
  }
}
