// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "controllers";
import bootstrap from "bootstrap/dist/js/bootstrap";
window.bootstrap = require("bootstrap/dist/js/bootstrap");
import "../stylesheets/application";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import intlTelInput from "intl-tel-input";
import "packs/jq-datatables.min.js";
import "trix";
import "@rails/actiontext";
import "packs/youtube";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

document.addEventListener("turbolinks:load", function () {
  instantiateFlatpickr();
  instantiateBootstrapElements();
  showFlashMessages();
  showPageLoadModals();
  setUpIntlInput();
  observeNewIntlInput();
});

document.addEventListener("turbolinks:before-cache", function () {
  $(".flatpickr").flatpickr("destroy");
  $(".flatpickr-multi").flatpickr("destroy");
  $(".flatpickr-inline").flatpickr("destroy");
  $(".flatpickr-autosave").flatpickr("destroy");
  $("#dataTable").DataTable().destroy();

  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    bootstrap.Tooltip.getInstance(tooltipTriggerEl).hide();
  });

  var notificationModal = document.querySelector("#notificationModal");
  if (notificationModal === null) {
    return false;
  }
  var myModal = bootstrap.Modal.getInstance(notificationModal);
  myModal.dispose();
});

function instantiateFlatpickr() {
  flatpickr(".flatpickr", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    disableMobile: true,
  });

  flatpickr(".flatpickr-times", {
    altInput: true,
    altFormat: "F j, Y h:i K", // Display format with time
    dateFormat: "Y-m-d H:i", // Submission format with time
    enableTime: true, // Enable time selection
    time_24hr: false, // Use 12-hour format
    disableMobile: true,
  });

  flatpickr(".flatpickr-inline", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    disableMobile: true,
    inline: true,
    minDate: "today",
    monthSelectorType: "static",
  });

  flatpickr(".flatpickr-autosave", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    disableMobile: true,
    minDate: "today",
    monthSelectorType: "static",
    onChange: function (selectedDates, dateStr, instance) {
      let baseUrl = this.element.dataset.url;
      window.location.href = `${baseUrl}?date=${dateStr}`;
    },
  });

  flatpickr(".flatpickr-multi", {
    altInput: true,
    mode: "multiple",
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    minDate: "today",
    disableMobile: true,
  });

  $(".flatpickr-mobile").flatpickr("destroy");
  $(".flatpickr-mobile").remove();
}

function instantiateBootstrapElements() {
  var popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]')
  );
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl);
  });

  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    var tooltip = new bootstrap.Tooltip(tooltipTriggerEl);
    tooltipTriggerEl.addEventListener("click", function () {
      tooltip.hide();
    });
  });

  var toastElList = [].slice.call(document.querySelectorAll(".toast"));
  var toastList = toastElList.map(function (toastEl) {
    return new bootstrap.Toast(toastEl);
  });
}

function showFlashMessages() {
  var flashSuccess = document.querySelector(".flash-success");
  var successToast = bootstrap.Toast.getInstance(flashSuccess);
  if (flashSuccess != null) {
    successToast.show();
  }

  var flashError = document.querySelector(".flash-error");
  var errorToast = bootstrap.Toast.getInstance(flashError);
  if (flashError != null) {
    errorToast.show();
  }
}

function showPageLoadModals() {
  var modal = document.querySelector("#notificationModal");
  if (modal === null) {
    return false;
  }

  var myModal = new bootstrap.Modal(modal, {
    keyboard: false,
    backdrop: "static",
  });

  myModal.show();
}

function setUpIntlInput() {
  const input = document.querySelectorAll(".intl-phone");
  if (input.length == 0) {
    return false;
  }

  for (var i = 0; i < input.length; i++) {
    intlTelInput(input[i], {
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.js",
      separateDialCode: true,
      hiddenInput: input[i].dataset.hiddeninput,
    });
  }
}

function observeNewIntlInput() {
  const observer = new MutationObserver(function (mutations_list) {
    mutations_list.forEach(function (mutation) {
      mutation.addedNodes.forEach(function (added_node) {
        if (added_node.id == "newIntlInput") {
          const input = added_node.querySelector(".intl-phone");
          intlTelInput(input, {
            utilsScript:
              "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.js",
            separateDialCode: true,
            hiddenInput: input.dataset.hiddeninput,
          });
          //observer.disconnect();
        }
      });
    });
  });
  const toObserve = document.querySelector(".observeMutation");
  if (toObserve) {
    observer.observe(document.querySelector(".observeMutation"), {
      subtree: false,
      childList: true,
    });
  }
}
require("trix");
require("@rails/actiontext");
