export class TableTimers {
  constructor(tablesHash) {
    this.tablesHash = tablesHash;
  }

  addTimers() {
    const canvas = document.querySelector("#canvas").fabric;
    const tablesHash = this.tablesHash;

    canvas.forEachObject((object) => {
      const timer = tablesHash[object.table_id];
      if (object.kind === "table" && tablesHash[object.table_id]) {
        var text = new fabric.Text(timer, {
          fontSize: 16,
          originX: "center",
          originY: "center",
          fill: "#000",
          selectable: false,
        });

        var bg = new fabric.Rect({
          fill: "rgb(229 231 235)",
          scaleY: 0.5,
          originX: "center",
          originY: "center",
          rx: 5,
          ry: 5,
          width: text.width + 5,
          height: 40,
          selectable: false,
        });

        var angle = object.angle % 360; // Normalize angle to be within 0-359 degrees

        // Determine which algorithm to use based on the rotation angle
        if (angle === 90 || angle === 270) {
          // Use height-based positioning for 90 or 270 degrees
          var group = new fabric.Group([bg, text], {
            left: object.left - (object.height * object.scaleX) / 2 - 55,
            top: object.top - (object.width * object.scaleY) / 2 - 10,
            timer: true,
            tableId: object.table_id,
            selectable: false,
          });
        } else {
          // Use width-based positioning for 0, 180, or other angles
          var group = new fabric.Group([bg, text], {
            left: object.left - (object.width * object.scaleX) / 2 - 55,
            top: object.top - (object.height * object.scaleY) / 2 - 10,
            timer: true,
            tableId: object.table_id,
            selectable: false,
          });
        }

        canvas.add(group);
      }
    });

    canvas.renderAll();
  }

  removeTimers() {
    const canvas = document.querySelector("#canvas").fabric;
    canvas.forEachObject((obj) => {
      if (obj.type === "group" && obj.timer === true) {
        canvas.remove(obj);
      }
    });

    canvas.renderAll();
  }
}
