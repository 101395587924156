import { Controller } from "stimulus"
import { fabric } from 'fabric';

export default class extends Controller {

  connect() {}

  zoomOut() {
    const htmlCanvas = document.querySelector('#canvas')
    const canvas = htmlCanvas.fabric
    let vpt = canvas.viewportTransform
    let zoom = parseFloat(canvas.getZoom())

    zoom -= 0.1
    if (zoom < 0.1) zoom = 0.1

    vpt[0] = zoom
    vpt[3] = zoom
    canvas.setViewportTransform(vpt);
    canvas.requestRenderAll()

    htmlCanvas.dataset.zoom = zoom
    htmlCanvas.dataset.translatey = vpt[5]
    htmlCanvas.dataset.translatex = vpt[4]
  }

  zoomIn() {
    const htmlCanvas = document.querySelector('#canvas')
    const canvas = htmlCanvas.fabric
    let vpt = canvas.viewportTransform
    let zoom = parseFloat(canvas.getZoom())

    zoom += 0.1
    if (zoom > 2) zoom = 2
  
    vpt[0] = zoom
    vpt[3] = zoom
    canvas.setViewportTransform(vpt)
    canvas.requestRenderAll()

    htmlCanvas.dataset.zoom = zoom
    htmlCanvas.dataset.translatey = vpt[5]
    htmlCanvas.dataset.translatex = vpt[4]
  }

  moveUp() {
    const htmlCanvas = document.querySelector('#canvas')
    const canvas = htmlCanvas.fabric;

    var unitsToMoveDown = 10;

    var vpt = canvas.viewportTransform;
    vpt[5] -= unitsToMoveDown;

    canvas.setViewportTransform(vpt);
    canvas.requestRenderAll();

    htmlCanvas.dataset.translatey = vpt[5]
  }

  moveDown() {
    const htmlCanvas = document.querySelector('#canvas')
    const canvas = htmlCanvas.fabric;

    var unitsToMoveDown = 10;

    var vpt = canvas.viewportTransform;
    vpt[5] += unitsToMoveDown;

    canvas.setViewportTransform(vpt);
    canvas.requestRenderAll();

    htmlCanvas.dataset.translatey = vpt[5]
  }

  moveLeft() {
    const htmlCanvas = document.querySelector('#canvas')
    const canvas = htmlCanvas.fabric;

    var unitsToMoveLeft = 10;
    var vpt = canvas.viewportTransform;

    vpt[4] -= unitsToMoveLeft;

    canvas.setViewportTransform(vpt);
    canvas.requestRenderAll();

    htmlCanvas.dataset.translatex = vpt[4]
  }

  moveRight() {
    const htmlCanvas = document.querySelector('#canvas')
    const canvas = htmlCanvas.fabric;

    var unitsToMoveLeft = 10;
    var vpt = canvas.viewportTransform;

    vpt[4] += unitsToMoveLeft;

    canvas.setViewportTransform(vpt);
    canvas.requestRenderAll();

    htmlCanvas.dataset.translatex = vpt[4]
  }
}
