import { Controller } from "stimulus"

export default class extends Controller {
  connect() {}

  phone(event) {
    if (event.target.parentNode.querySelector('.iti__selected-flag') == null || event.target.parentNode.querySelector('.iti__selected-flag').title == "United States: +1") {
      var x = event.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      event.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    }
  }
}
